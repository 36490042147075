import React from "react";
import FireRemoteService from "../../core/services/fire-remote";
import AppStore from "../../Store";
import "./footer.scss";

export default class Footer extends React.Component<{
  appStore: AppStore;
  history: any;
}> {
  render() {
    return (
      <div id="footer">
        <div className="my-footer">
          <div className="row m-0 p-0">
            <div className="col-4 col-md-3 p-0">
              <div className="youtube footer-boxes left-box">
                OPEN OFFICE 4 PLAY!
                <a
                target='blank' rel="noopener noreferrer"
                  href={FireRemoteService.getStringValue(
                    FireRemoteService.youtubeUrl
                  )}
                >
                  <img
                    onClick={() => ""}
                    src="/assets/images/youtube-logo.png"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="col-4 col-md-3 p-0">
              <div className="linkedin footer-boxes">
                OPEN OFFICE 4 POSITION!
                <a
                target='_blank' rel="noopener noreferrer"
                  href={FireRemoteService.getStringValue(
                    FireRemoteService.linkedinUrl
                  )}
                >
                  <img
                  className="linkedin-image"
                    onClick={() => ""}
                    src="/assets/images/linkedin_logo.png"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="col-4 p-0">
              <div className="for-more footer-boxes">
                FOR MORE{" "}
                <a
                target='_blank' rel="noopener noreferrer"
                  href={FireRemoteService.getStringValue(
                    FireRemoteService.instagramUrl
                  )}
                >
                  <img
                    onClick={() => ""}
                    src="/assets/images/instagram_logo.png"
                    alt=""
                  />
                </a>
                <a
                target='_blank' rel="noopener noreferrer"
                  href={FireRemoteService.getStringValue(
                    FireRemoteService.twitterUrl
                  )}
                >
                  <img
                  className="ml-lg-1"
                    onClick={() => ""}
                    src="/assets/images/twitter-logo.png"
                    alt=""
                  />
                </a>
                <a
                  target='_blank' rel="noopener noreferrer"
                  href={FireRemoteService.getStringValue(
                    FireRemoteService.mailUrl
                  )}
                >
                  <img
                  className="ml-lg-1"
                    onClick={() => ""}
                    src="/assets/images/mail-logo.png"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
