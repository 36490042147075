import fire from "../../fire";

export default class FireRemoteService {
  public static linkedinUrl: string = "contact_us_linkedin_url";
  public static youtubeUrl: string = "contact_us_youtube_url";
  public static instagramUrl: string = "contact_us_instagram_url";
  public static twitterUrl: string = "contact_us_twitter_url";
  public static mailUrl: string = "contact_us_mail_url";

  public static kimGbIsterAppUrl: string = "products_kgb_app_url";
  public static gbBusinessUrl: string = "products_kkgb_url";
  public static kimGbIsterUrl: string = "products_kimgbister_url";
  public static kazanKazanUrl: string = "products_kazankazan_url";
  public static biSohbetUrl: string = "products_bisohbet_url";
  public static toplaKazanUrl: string = "products_toplakazan_url";
  public static tribunCepteUrl: string = "products_tribuncepte_url";
  public static dinletKazanUrl: string = "products_dinletkazan_url";

  public static home_popup_title: string = "home_popup_title";
  public static home_popup_desc1: string = "home_popup_desc1";
  public static home_popup_desc2: string = "home_popup_desc2";
  public static home_popup_icon: string = "home_popup_icon";
  public static home_popup_bg: string = "home_popup_bg";

  public static async fetchAndActivateRemoteConfig(): Promise<void> {
    await fire
      .remoteConfig()
      .fetchAndActivate()
      .then(() => {})
      .catch(() => {});
  }

  public static getStringValue(key: string): string {
    return fire.remoteConfig().getString(key);
  }
}
