import { inject, observer } from "mobx-react";
import React from "react";
import "./home.scss";
import AppStore from "../../Store";
import Header from "../../components/header/header";

@inject("appStore")
@observer
export default class HomePage extends React.Component<{
  appStore: AppStore;
  history: any;
}> {

  componentDidMount() {
    setTimeout(() => {
      this.props.appStore.setModalHomeAvailable()
    }, 800);
  }

  render() {
    return (
      <div id="home">
        <Header appStore={this.props.appStore} history={this.props.history} />
        <div className="we-do-box">
          <div className="we-do">
            <div className="head-text">
              what <br />
              we <br />
              do
            </div>
            <div className="we-do-content-mobile">
              <div className="we-do-lines first-line-mobile">
                <span className="extra-bold">digital </span>
                <span className="extra-bold">product</span>
                <img src="/assets/images/4play.png" alt="" />
                <span className="idea extra-bold italic" style={{overflow: "unset"}}>
                  idea{" "}
                  <img
                    className="design bold-italic"
                    src="/assets/images/green-design.gif"
                    alt=""
                  />
                </span>
              </div>
              <div className="we-do-lines second-line-mobile">
                <span className="bold">communication</span>
                <span className="extra-bold italic">companionship</span>
              </div>
              <div className="we-do-lines third-line-mobile">
                <span className="glass-meme extra-bold italic">
                  <img src="/assets/images/sun-glass.png" alt="" />
                  meme
                </span>
                <span className="bold">fire</span>
                <span className="firebox">
                  <img
                    className="fire-design"
                    src="/assets/images/fire-design.gif"
                    alt=""
                  />
                  <img src="/assets/images/fire.jpg" alt="" />
                </span>
                <span className="extra-bold italic">creativity</span>
              </div>
              <div className="we-do-lines fourth-line-mobile bold">
                <span className="smaller-text">remote working</span>{" "}
                <span className="extra-bold italic smaller-text">oriented decision</span>
              </div>
              <div className="we-do-lines fifth-line-mobile bold">
                <img className="data" src="/assets/images/data.png" alt="" />{" "}
                <span className="extra-bold italic">exceed</span>
                <span className="expectations extra-bold italic">
                  expectations
                </span>
              </div>
              <div className="we-do-lines sixth-line-mobile bold">
                <span className="bold">inspire</span>
                <span className="skate-box bold-italic">
                  {" "}
                  <span className="skate-text extra-bold italic">
                    skate{" "}
                  </span>{" "}
                  <img
                    className="skate"
                    src="/assets/images/skate.jpg"
                    alt=""
                  />
                </span>{" "}
                <span className="coffee-box">
                  coffee
                  <img
                    className="coffee"
                    src="/assets/images/coffee.png"
                    alt=""
                  />{" "}
                </span>{" "}
              </div>
            </div>

            <div className="we-do-content">
              <div className="we-do-lines first-line-mobile">
                <span className="extra-bold">digital product</span>

                <img src="/assets/images/4play.png" alt="" />
                <span className="bold"><span className="extra-bold">idea</span> communication</span>
                <span className="idea">
                  <img
                    className="design bold-italic"
                    src="/assets/images/green-design.gif"
                    alt=""
                  />
                </span>
                <span className="extra-bold italic">companionship</span>
              </div>
              <div className="we-do-lines second-line-mobile">
                <span className="glass-meme extra-bold italic">
                  inspire
                  <img src="/assets/images/sun-glass.png" alt="" />
                  meme
                </span>
                <span className="bold">fire</span>
                <span className="firebox">
                  <img
                    className="fire-design"
                    src="/assets/images/fire-design.gif"
                    alt=""
                  />
                  <img src="/assets/images/fire.jpg" alt="" />
                </span>
                <span className="extra-bold italic">remote working</span>{" "}
                <span>creativity</span>
                <span className="extra-bold">exceed</span>
              </div>
              <div className="we-do-lines third-line-mobile">
                <span className="extra-bold italic">expectations</span>
                <img
                  className="data"
                  src="/assets/images/data.png"
                  alt=""
                />{" "}
                <span>oriented decision</span>
                <span className="skate-box bold-italic">
                  {" "}
                  <span className="skate-text extra-bold italic">
                    skate{" "}
                  </span>{" "}
                  <img
                    className="skate"
                    src="/assets/images/skate.jpg"
                    alt=""
                  />
                </span>{" "}
                <span className="coffee-box">
                 <span className="coffee-text">coffee</span>
                  <img
                    className="coffee"
                    src="/assets/images/coffee.png"
                    alt=""
                  />{" "}
                </span>{" "}
              </div>
            </div>
          </div>

          <div className="we-dont">
            <div className="head-text-dont">
              what <br />
              we <br />
              don't
            </div>
            <div className="we-dont-lines firsted">
              <span className="bold-text"> sacrifice quality for profit </span>{" "}
              <span className="regular-text">ego</span>{" "}
              <span className="bold-text">ideas we dont believe</span>{" "}
            </div>
            <div className="we-dont-lines">
              {" "}
              <span className="regular-text">count work hour</span> <span className="bold-text"> fax </span>{" "}
              <span className="regular-text">replicate</span>{" "}
              <span className="bold-text">have a sales team</span>{" "}
              <span className="regular-text"> outsource </span>{" "}
            </div>
            <div className="we-dont-lines">
              <span className="bold-text">anything conventional</span>{" "}
              <span className="regular-text"> order </span>{" "}
              <span className="bold-text">resist bad music</span>{" "}
              <span className="regular-text"> impossible </span>{" "}
            </div>
            <div className="we-dont-lines lefted">
              {" "}
              <span className="regular-text"> deadlines </span>{" "}
              <span className="bold-text ml-3">make &nbsp; &nbsp; no &nbsp; &nbsp; progress</span>{" "}
              <span className="ml-3 regular-text"> diet </span>{" "}
            </div>
          </div>
        </div>

        <div className="we-dont-mobile">
          <div className="head-text-dont">
            what <br />
            we <br />
            don't
          </div>

          <div className="we-dont-lines">
            <span className="bold-text smaller-text">sacrifice quality for profit</span>{" "}
            <span className="regular-text"> ego </span>{" "}
          </div>
          <div className="we-dont-lines">
            {" "}
            <span className="regular-text">count work hour</span> <span className="bold-text"> fax </span>{" "}
            <span className="regular-text">replicate</span>{" "}
          </div>
          <div className="we-dont-lines">
            {" "}
            <span className="bold-text smaller-text">ideas we dont believe</span>{" "}
            <span className="regular-text"> deadlines </span>{" "}
          </div>
          <div className="we-dont-lines">
            {" "}
            <span className="regular-text"> impossible </span>{" "}
            <span className="bold-text">resist bad music</span>{" "}
          </div>
          <div className="we-dont-lines">
            {" "}
            <span className="bold-text">have a sales team</span>{" "}
            <span className="regular-text"> outsource </span>{" "}
          </div>
          <div className="we-dont-lines">
            {" "}
            <span className="regular-text"> order </span>{" "}
            <span className="bold-text smaller-text">anything conventional</span>{" "}
            <span className="regular-text"> diet </span>{" "}
          </div>
          <div className="we-dont-lines">
            {" "}
            <span className="bold-text">make no progress</span>{" "}
          </div>
        </div>

        {/*<img*/}
        {/*  id="home-popup-icon"*/}
        {/*  src={FireRemoteService.getStringValue(FireRemoteService.home_popup_icon)}*/}
        {/*  onClick={() => this.props.appStore.setModalHomeVisibility(true)}*/}
        {/*  alt="icon"*/}
        {/*/>*/}
      </div>
    );
  }
}
