import { inject, observer } from "mobx-react";
import React from "react";
import "./our-people.scss";
import AppStore from "../../Store";
import Header from "../../components/header/header";
import { OurPeopleState } from "../../core/interfaces/states/our-people.state";
import FireRemoteService from "../../core/services/fire-remote";
import { AllPeopleDto } from "../../core/interfaces/dtos/AllPeopleDto.dto";
import { Gender } from "../../core/interfaces/enum/gender";


@inject("appStore")
@observer
export default class OurPeople extends React.Component<{
  appStore: AppStore;
  history: any;
}> {
  state: OurPeopleState = {
    height: window.innerHeight,
    allPeople: [],
    chosenPerson: undefined,
    backgroundColorController: 0,
    showBadge: false,
  };

  private listenWindowSize = () => {
    this.setState({
      height: window.innerHeight,
    });
  };

  async componentDidMount() {
    window.addEventListener("resize", this.listenWindowSize);

    this.handleBadgeDisplay()

    let peopleJson = JSON.parse(
      FireRemoteService.getStringValue("our_people_personel_info")
    );
    console.log(peopleJson);
    let people: AllPeopleDto[] = Object.values(peopleJson);
    console.log(people.map((a) => a.name));

    await this.setState({
      allPeople: people,
    });
  }

  private handlePeopleBoxesClick = async (section: number, id: number) => {
    await this.setState({
      backgroundColorController: section,
      chosenPerson: this.state.allPeople.filter(
        (chosen) => chosen.id === id
      )[0],
    });
  };

  private convertTrCharactersToEn = (name: string): string => {
    //I know this is a bad solution but have no time to fix
    let changedName = name
      .replace(/Ç/g, "C")
      .replace(/ç/g, "c")
      .replace(/ğ/g, "g")
      .replace(/ı/g, "i")
      .replace(/ü/g, "u")
      .replace(/Ş/g, "S")
      .replace(/ş/g, "s")
      .replace(/Ö/g, "O")
      .replace(/ö/g, "o")
      .replace(/İ/g, "I");
    return changedName;
  };

  private RenderGender = (gender: Gender): string => {
    let chosenGender: string;
    switch (gender) {
      case Gender.male:
        chosenGender = "him";
        break;

      case Gender.female:
        chosenGender = "her";
        break;
    }

    return chosenGender;
  };

  private handleBadgeDisplay = () => {
    if(this.state.chosenPerson?.badges === ""){
      this.setState({showBadge: false})
    } else {
      this.setState({showBadge: true})
    }
  };

  private handleNewLine = (badges: string):string => {
    let newLine = badges
    .replace(/\|/g, `\n / `);
      return newLine;
  }

  render() {
    return (
      <div id="our-people" style={{ height: this.state.height }}>
        <Header appStore={this.props.appStore} history={this.props.history} />
        <div className="content">
          <div className="row top m-0 p-0">
            <div className="col-6 p-0">
              <div className={`people-boxes top-left-box`}>
                {this.state.allPeople
                  .filter((position) => position.position === 1)
                  .map((item, i) => {
                    return (
                      <img
                        id={`top-left-${item.id}`}
                        key={`top-left-${item.id}`}
                        src={`/assets/images/people/${this.convertTrCharactersToEn(
                          item.name
                        )}.svg`}
                        onClick={() =>
                          this.handlePeopleBoxesClick(item.position, item.id)
                        }
                        alt={this.convertTrCharactersToEn(item.name)}
                      />
                    );
                  })}
              </div>
            </div>
            <div className="col-6 p-0">
              <div className={`people-boxes top-right-box`}>
                {this.state.allPeople
                  .filter((position) => position.position === 2)
                  .map((item, i) => {
                    return (
                      <img
                        id={`top-right-${item.id}`}
                        key={`top-right-${item.id}`}
                        src={`/assets/images/people/${this.convertTrCharactersToEn(
                          item.name
                        )}.svg`}
                        onClick={() =>
                          this.handlePeopleBoxesClick(item.position, item.id)
                        }
                        alt={this.convertTrCharactersToEn(item.name)}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="row bottom m-0 p-0">
            <div className="col-6 p-0">
              <div className={`people-boxes bottom-left-box`}>
                {this.state.allPeople
                  .filter((position) => position.position === 3)
                  .map((item, i) => {
                    return (
                      <img
                        id={`bottom-left-${item.id}`}
                        key={`bottom-left-${item.id}`}
                        src={`/assets/images/people/${this.convertTrCharactersToEn(
                          item.name
                        )}.svg`}
                        onClick={() =>
                          this.handlePeopleBoxesClick(item.position, item.id)
                        }
                        alt={this.convertTrCharactersToEn(item.name)}
                      />
                    );
                  })}
              </div>
            </div>
            <div className="col-6 p-0">
              <div className={`people-boxes bottom-right-box`}>
                {" "}
                {this.state.allPeople
                  .filter((position) => position.position === 4)
                  .map((item, i) => {
                    return (
                      <img
                        id={`bottom-right-${item.id}`}
                        key={`bottom-right-${item.id}`}
                        src={`/assets/images/people/${this.convertTrCharactersToEn(
                          item.name
                        )}.svg`}
                        onClick={() =>
                          this.handlePeopleBoxesClick(item.position, item.id)
                        }
                        alt={this.convertTrCharactersToEn(item.name)}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
          <div
            className={`people-big-box people-${this.state.backgroundColorController}`}
          >
            <div className="people-info">
              {this.state.chosenPerson ? (
                <>
                  <img
                    className="info-image"
                    src={`/assets/images/people-info-images/${this.convertTrCharactersToEn(
                      this.state.chosenPerson?.name
                    )}.png`}
                    alt={`${this.convertTrCharactersToEn(
                      this.state.chosenPerson?.name
                    )}`}
                  />
                  <ul className="person-info-card">
                    {this.state.chosenPerson?.name}
                    <li className="info-card-lines">
                      <div className="line-content-head">They know as</div>
                      <div className="line-content">
                        {" / "}
                        {this.state.chosenPerson?.title}
                      </div>
                    </li>
                    <li className={`info-card-lines ${this.state.chosenPerson?.badges ? "" : "unvisible"}`}>
                      <div className="line-content-head">Badges</div>
                      <div className="line-content">
                        
                        
                          <p className="new-line">{" / "}{this.state.chosenPerson?.badges ? this.handleNewLine(this.state.chosenPerson?.badges)  : this.state.chosenPerson?.badges}</p>
                                          
                      </div>
                    </li>
                    <li className="info-card-lines">
                      <div className="line-content-head">Powerful at</div>
                      <div className="line-content">
                        {" / "}
                        {this.state.chosenPerson?.powerful}<br/>
                        {" / "}
                        {this.state.chosenPerson.department}
                      </div>
                    </li>
                    <li className="info-card-lines">
                      <div className="line-content-head">
                        We call{" "}
                        {this.RenderGender(this.state.chosenPerson.gender)}{" "}
                      </div>
                      <div className="line-content">
                        {" / "}
                        {this.state.chosenPerson?.nickname}
                      </div>
                    </li>
                    <li className="info-card-lines">
                      <div className="line-content-head">Location </div>
                      <div className="line-content">
                        {" / "}
                        {this.state.chosenPerson?.location}
                      </div>
                    </li>
                    <li className="info-card-lines bottom-line align-items-center">
                      <div className="line-content-head">Wanna meet </div>
                      <div className="line-content-body">
                        {this.state.chosenPerson.links.linkedin ? <a target="blank" href={this.state.chosenPerson.links.linkedin}><img src="/assets/images/linkedin.svg" alt="linkedin" className="info-img"/></a> : ""}
                        {this.state.chosenPerson.links.instagram ? <a target="blank" href={this.state.chosenPerson.links.instagram}><img src="/assets/images/insta.svg" alt="instagram" className="info-img"/></a> : ""}
                        {this.state.chosenPerson.links.behance ? <a target="blank" href={this.state.chosenPerson.links.behance}><img src="/assets/images/behance.svg" alt="behance" className="info-img"/></a> : ""}
                      </div>
                    </li>
                  </ul>
                </>
              ) : (
                <div className="everybody-content">
                  <div className="everybody-content-img">
                    <img
                      className="info-image"
                      src={`/assets/images/people-info-images/everybody.svg`}
                      alt="4PLAY Logo"
                    />
                  </div>
                  <div className="everybody-content-text mt-3">
                    <span>Click on 4PLAYERS to meet!</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
