import { inject, observer } from "mobx-react";
import React from "react";
import "./contact.scss";
import AppStore from "../../Store";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import FireRemoteService from "../../core/services/fire-remote";

@inject("appStore")
@observer
export default class Contact extends React.Component<{
  appStore: AppStore;
  history: any;
}> {
  state = {
    height: window.innerHeight,
  };

  private listenWindowSize = () => {
    this.setState({
      height: window.innerHeight,
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.listenWindowSize);
  }

  render() {
    return (
      <div id="contact" style={{ height: this.state.height }}>
        <Header appStore={this.props.appStore} history={this.props.history} />
        <div className="contact-content">
          <div className="contact-image">
            <a 
            target='_blank' rel="noopener noreferrer"
              href={FireRemoteService.getStringValue(
                FireRemoteService.youtubeUrl
              )}
            >
              <img src="/assets/images/oops.png" alt="" />
            </a>
          </div>
        </div>
        <Footer appStore={this.props.appStore} history={this.props.history} />
      </div>
    );
  }
}
