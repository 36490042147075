import { CarouselRef } from "antd/es/carousel";
import { inject, observer } from "mobx-react";
import React from "react";
import "./products.scss";
import AppStore from "../../Store";
import Header from "../../components/header/header";
import { AboutUsState } from "../../core/interfaces/states/about-us.state";
import FireRemoteService from "../../core/services/fire-remote";
import Media from "react-media";
import { Carousel } from "antd";

@inject("appStore")
@observer
export default class Products extends React.Component<{
  appStore: AppStore;
  history: any;
}> {
  state: AboutUsState = {
    height: window.innerHeight,
    chosenPart: "zero",
    chosenPartFooter: "first",
    backgroundColorController: 0,
    visibleArrowFooter: "none",
  };

  private refFooterCarousel = React.createRef<CarouselRef>();

  private listenWindowSize = () => {
    this.setState({
      height: window.innerHeight,
    });
  };

  async componentDidMount() {
    window.addEventListener("resize", this.listenWindowSize);
  }

  private handleArrowClick = async (part: string) => {
    switch (part) {
      case "left":
        await this.setState(() => {
          switch (this.state.chosenPart) {
            case "zero":
              return { chosenPart: "second" };
            case "first":
              return { chosenPart: "zero" };
            case "second":
              return { chosenPart: "first" };
          }
        });
        break;

      case "right":
        await this.setState(() => {
          switch (this.state.chosenPart) {
            case "zero":
              return { chosenPart: "first" };
            case "first":
              return { chosenPart: "second" };
            case "second":
              return { chosenPart: "zero" }
          }
        });
        break;
    }
  };

  private handleArrowClickFooterMobile = async (part: string) => {
    this.setState({
      chosenPartFooter: part,
    });
    if (part === 'first') {
      this.refFooterCarousel.current?.prev();
    } else {
      this.refFooterCarousel.current?.next();
    }
  }

  private handleArrowClickFooter = async (part: string) => {
    this.setState({
      chosenPartFooter: part,
    });
    if (part === "second") {
      this.setState({
        visibleArrowFooter: "inline"
      })
    }
    else {
      this.setState({
        visibleArrowFooter: "none"
      })
    }
  };

  render() {
    return (
      <div id="products">
        <Header appStore={this.props.appStore} history={this.props.history} />

        <div className="content container">
          <Media query="(max-width: 480px)" render={() => (
            <Carousel>
              <div className="zero">
                <div className="row">
                  <div className="col-6 mt-3 product-card-content">
                    <a
                      target="blank"
                      rel="noopener noreferrer"
                      href={FireRemoteService.getStringValue(
                        FireRemoteService.kimGbIsterAppUrl
                      )}
                    >
                      <div className="product-card">
                        <img
                          className="card-image"
                          src={`/assets/images/products/gb-app-logo.png`}
                          alt=""
                        />
                        <img
                          className="card-info"
                          src={`/assets/images/products/gb-app-info.svg`}
                          alt=""
                        />
                      </div>
                    </a>
                  </div>
                  <div className="col-6 mt-3 product-card-content">
                    <a
                      target="blank"
                      rel="noopener noreferrer"
                      href={FireRemoteService.getStringValue(
                        FireRemoteService.gbBusinessUrl
                      )}
                    >
                      <div className="product-card">
                        <img
                          className="card-image"
                          src={`/assets/images/products/gb-business-logo.png`}
                          alt=""
                        />
                        <img
                          className="card-info"
                          src={`/assets/images/products/gb-business-info.svg`}
                          alt=""
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="first">
                <div className="row">
                  <div className="col-6 col-md-4 mt-3 product-card-content">
                    <a
                      target="blank"
                      rel="noopener noreferrer"
                      href={FireRemoteService.getStringValue(
                        FireRemoteService.biSohbetUrl
                      )}
                    >
                      <div className="product-card">
                        <img
                          className="card-image"
                          src={`/assets/images/products/bisohbet.png`}
                          alt=""
                        />
                        <img
                          className="card-info"
                          src={`/assets/images/products/bisohbetbilgi.svg`}
                          alt=""
                        />
                      </div>
                    </a>
                  </div>
                  <div className="col-6 col-md-4 mt-3 product-card-content">
                    <a
                      target="blank"
                      rel="noopener noreferrer"
                      href={FireRemoteService.getStringValue(
                        FireRemoteService.kimGbIsterUrl
                      )}
                    >
                      <div className="product-card">
                        <img
                          className="card-image"
                          src={`/assets/images/products/kim-gb-ister.png`}
                          alt=""
                        />
                        <img
                          className="card-info"
                          src={`/assets/images/products/kimgbisterbilgi.svg`}
                          alt=""
                        />
                      </div>
                    </a>
                  </div>
                  <div className="col-6 col-md-4 mt-3 product-card-content">
                    <a
                      target="blank"
                      rel="noopener noreferrer"
                      href={FireRemoteService.getStringValue(
                        FireRemoteService.kazanKazanUrl
                      )}
                    >
                      <div className="product-card">
                        <img
                          className="card-image"
                          src={`/assets/images/products/kazan-kazan.png`}
                          alt=""
                        />
                        <img
                          className="card-info"
                          src={`/assets/images/products/kazankazanbilgi.svg`}
                          alt=""
                        />
                      </div>
                    </a>
                  </div>
                  <div className="col-6 col-md-4 mt-3 product-card-content">
                    <a
                      target="blank"
                      rel="noopener noreferrer"
                      href={FireRemoteService.getStringValue(
                        FireRemoteService.dinletKazanUrl
                      )}
                    >
                      <div className="product-card">
                        <img
                          className="card-image"
                          src={`/assets/images/products/dinlet-kazan.png`}
                          alt=""
                        />
                        <img
                          className="card-info"
                          src={`/assets/images/products/dinletkazanbilgi.svg`}
                          alt=""
                        />
                      </div>
                    </a>
                  </div>
                  <div className="col-6 col-md-4 mt-3 product-card-content">
                    <a
                      target="blank"
                      rel="noopener noreferrer"
                      href={FireRemoteService.getStringValue(
                        FireRemoteService.tribunCepteUrl
                      )}
                    >
                      <div className="product-card">
                        <img
                          className="card-image"
                          src={`/assets/images/products/tribun-cepte.png`}
                          alt=""
                        />
                        <img
                          className="card-info"
                          src={`/assets/images/products/tribunceptebilgi.svg`}
                          alt=""
                        />
                      </div>
                    </a>
                  </div>
                  <div className="col-6 col-md-4 mt-3 product-card-content">
                    <a
                      target="blank"
                      rel="noopener noreferrer"
                      href={FireRemoteService.getStringValue(
                        FireRemoteService.toplaKazanUrl
                      )}
                    >
                      <div className="product-card">
                        <img
                          className="card-image"
                          src={`/assets/images/products/sut-ve-kazan.png`}
                          alt=""
                        />
                        <img
                          className="card-info"
                          src={`/assets/images/products/sut-ve-kazan-bilgi.svg`}
                          alt=""
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="second">
                <div className="row">
                  <div className="col-4 col-md-3 mt-3 second-content">
                    <img
                      className="card-image"
                      src={`/assets/images/products/statune.png`}
                      alt=""
                    />
                    <p className="card-text">Statune</p>
                  </div>
                  <div className="col-4 col-md-3 mt-3 second-content">
                    <img
                      className="card-image"
                      src={`/assets/images/products/toplantidayim.png`}
                      alt=""
                    />
                    <p className="card-text">Toplantıdayım</p>
                  </div>
                  <div className="col-4 col-md-3 mt-3 second-content">
                    <img
                      className="card-image"
                      src={`/assets/images/products/hediye.png`}
                      alt=""
                    />
                    <p className="card-text">Hediye Bulmaca</p>
                  </div>
                  <div className="col-4 col-md-3 mt-3 second-content">
                    <img
                      className="card-image"
                      src={`/assets/images/products/atlar-cepte.png`}
                      alt=""
                    />
                    <p className="card-text">Atlar Cepte</p>
                  </div>
                  <div className="col-4 col-md-3 mt-3 second-content">
                    <img
                      className="card-image"
                      src={`/assets/images/products/tonla-kazan.png`}
                      alt=""
                    />
                    <p className="card-text">Tonla Kazan</p>
                  </div>

                  <div className="col-4 col-md-3 mt-3 second-content">
                    <img
                      className="card-image"
                      src={`/assets/images/products/meddah.png`}
                      alt=""
                    />
                    <p className="card-text">Meddah</p>
                  </div>

                  <div className="col-4 col-md-3 mt-3 second-content">
                    <img
                      className="card-image"
                      src={`/assets/images/products/on-numara-muhabbet.png`}
                      alt=""
                    />
                    <p className="card-text">10 Numara Muhabbet</p>
                  </div>

                  <div className="col-4 col-md-3 mt-3 second-content">
                    <img
                      className="card-image"
                      src={`/assets/images/products/bul-coz-kazan.png`}
                      alt=""
                    />
                    <p className="card-text">Bul Çöz Kazan</p>
                  </div>

                  <div className="col-4 col-md-3 mt-3 second-content">
                    <img
                      className="card-image wide"
                      src={`/assets/images/products/alt-ust.png`}
                      alt=""
                    />
                    <p className="card-text">Alt Üst</p>
                  </div>
                  <div className="col-4 col-md-3 mt-3 second-content">
                    <img
                      className="card-image wide"
                      src={`/assets/images/products/tarzim-cepte.png`}
                      alt=""
                    />
                    <p className="card-text">Tarzım Cepte</p>
                  </div>
                  <div className="col-4 col-md-3 mt-3 second-content">
                    <img
                      className="card-image"
                      src={`/assets/images/products/namaz-vakitleri.png`}
                      alt=""
                    />
                    <p className="card-text">Namaz Vakitleri</p>
                  </div>
                </div>
              </div>
            </Carousel>
          )} />
          <Media query="(min-width: 481px)" render={() => (
            <>
              <div className={`arrows`}>
                <img
                  src="/assets/images/about-us/left-arrow.svg"
                  onClick={() => this.handleArrowClick("left")}
                  className="left-arrow"
                  alt=""
                  style={{ display: this.state.chosenPart==="zero" ? "none" : "inline" }}
                />
                <img
                  src="/assets/images/about-us/right-arrow.svg"
                  onClick={() => this.handleArrowClick("right")}
                  className="right-arrow"
                  alt=""
                  style={{ display: this.state.chosenPart === "second" ? "none" : "inline"}}
                />
              </div>
              <div className="mt-5 px-md-3 px-sm-3 px-lg-5">
                {this.state.chosenPart === "zero" && (
                  <div className="zero">
                    <div className="row">
                      <div className="col-6 mt-3 px-4 product-card-content">
                        <a
                          target="blank"
                          rel="noopener noreferrer"
                          href={FireRemoteService.getStringValue(
                            FireRemoteService.kimGbIsterAppUrl
                          )}
                        >
                          <div className="product-card">
                            <img
                              className="card-image"
                              src={`/assets/images/products/gb-app-logo.png`}
                              alt=""
                            />
                            <img
                              className="card-info"
                              src={`/assets/images/products/gb-app-info.svg`}
                              alt=""
                            />
                          </div>
                        </a>
                      </div>
                      <div className="col-6 mt-3 px-4 product-card-content">
                        <a
                          target="blank"
                          rel="noopener noreferrer"
                          href={FireRemoteService.getStringValue(
                            FireRemoteService.gbBusinessUrl
                          )}
                        >
                          <div className="product-card">
                            <img
                              className="card-image"
                              src={`/assets/images/products/gb-business-logo.png`}
                              alt=""
                            />
                            <img
                              className="card-info"
                              src={`/assets/images/products/gb-business-info.svg`}
                              alt=""
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.chosenPart === "first" && (
                  <div className="first">
                    <div className="row">
                      <div className="col-6 col-md-4 mt-3 product-card-content">
                        <a
                          target="blank"
                          rel="noopener noreferrer"
                          href={FireRemoteService.getStringValue(
                            FireRemoteService.biSohbetUrl
                          )}
                        >
                          <div className="product-card">
                            <img
                              className="card-image"
                              src={`/assets/images/products/bisohbet.png`}
                              alt=""
                            />
                            <img
                              className="card-info"
                              src={`/assets/images/products/bisohbetbilgi.svg`}
                              alt=""
                            />
                          </div>
                        </a>
                      </div>
                      <div className="col-6 col-md-4 mt-3 product-card-content">
                        <a
                          target="blank"
                          rel="noopener noreferrer"
                          href={FireRemoteService.getStringValue(
                            FireRemoteService.kimGbIsterUrl
                          )}
                        >
                          <div className="product-card">
                            <img
                              className="card-image"
                              src={`/assets/images/products/kim-gb-ister.png`}
                              alt=""
                            />
                            <img
                              className="card-info"
                              src={`/assets/images/products/kimgbisterbilgi.svg`}
                              alt=""
                            />
                          </div>
                        </a>
                      </div>
                      <div className="col-6 col-md-4 mt-3 product-card-content">
                        <a
                          target="blank"
                          rel="noopener noreferrer"
                          href={FireRemoteService.getStringValue(
                            FireRemoteService.kazanKazanUrl
                          )}
                        >
                          <div className="product-card">
                            <img
                              className="card-image"
                              src={`/assets/images/products/kazan-kazan.png`}
                              alt=""
                            />
                            <img
                              className="card-info"
                              src={`/assets/images/products/kazankazanbilgi.svg`}
                              alt=""
                            />
                          </div>
                        </a>
                      </div>
                      <div className="col-6 col-md-4 mt-3 product-card-content">
                        <a
                          target="blank"
                          rel="noopener noreferrer"
                          href={FireRemoteService.getStringValue(
                            FireRemoteService.dinletKazanUrl
                          )}
                        >
                          <div className="product-card">
                            <img
                              className="card-image"
                              src={`/assets/images/products/dinlet-kazan.png`}
                              alt=""
                            />
                            <img
                              className="card-info"
                              src={`/assets/images/products/dinletkazanbilgi.svg`}
                              alt=""
                            />
                          </div>
                        </a>
                      </div>
                      <div className="col-6 col-md-4 mt-3 product-card-content">
                        <a
                          target="blank"
                          rel="noopener noreferrer"
                          href={FireRemoteService.getStringValue(
                            FireRemoteService.tribunCepteUrl
                          )}
                        >
                          <div className="product-card">
                            <img
                              className="card-image"
                              src={`/assets/images/products/tribun-cepte.png`}
                              alt=""
                            />
                            <img
                              className="card-info"
                              src={`/assets/images/products/tribunceptebilgi.svg`}
                              alt=""
                            />
                          </div>
                        </a>
                      </div>
                      <div className="col-6 col-md-4 mt-3 product-card-content">
                        <a
                          target="blank"
                          rel="noopener noreferrer"
                          href={FireRemoteService.getStringValue(
                            FireRemoteService.toplaKazanUrl
                          )}
                        >
                          <div className="product-card">
                            <img
                              className="card-image"
                              src={`/assets/images/products/sut-ve-kazan.png`}
                              alt=""
                            />
                            <img
                              className="card-info"
                              src={`/assets/images/products/sut-ve-kazan-bilgi.svg`}
                              alt=""
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.chosenPart === "second" && (
                  <div className="second">
                    <div className="row">
                      <div className="col-4 col-md-3 mt-3 second-content">
                        <img
                          className="card-image"
                          src={`/assets/images/products/statune.png`}
                          alt=""
                        />
                        <p className="card-text">Statune</p>
                      </div>
                      <div className="col-4 col-md-3 mt-3 second-content">
                        <img
                          className="card-image"
                          src={`/assets/images/products/toplantidayim.png`}
                          alt=""
                        />
                        <p className="card-text">Toplantıdayım</p>
                      </div>
                      <div className="col-4 col-md-3 mt-3 second-content">
                        <img
                          className="card-image"
                          src={`/assets/images/products/hediye.png`}
                          alt=""
                        />
                        <p className="card-text">Hediye Bulmaca</p>
                      </div>
                      <div className="col-4 col-md-3 mt-3 second-content">
                        <img
                          className="card-image"
                          src={`/assets/images/products/atlar-cepte.png`}
                          alt=""
                        />
                        <p className="card-text">Atlar Cepte</p>
                      </div>
                      <div className="col-4 col-md-3 mt-3 second-content">
                        <img
                          className="card-image"
                          src={`/assets/images/products/tonla-kazan.png`}
                          alt=""
                        />
                        <p className="card-text">Tonla Kazan</p>
                      </div>

                      <div className="col-4 col-md-3 mt-3 second-content">
                        <img
                          className="card-image"
                          src={`/assets/images/products/meddah.png`}
                          alt=""
                        />
                        <p className="card-text">Meddah</p>
                      </div>

                      <div className="col-4 col-md-3 mt-3 second-content">
                        <img
                          className="card-image"
                          src={`/assets/images/products/on-numara-muhabbet.png`}
                          alt=""
                        />
                        <p className="card-text">10 Numara Muhabbet</p>
                      </div>

                      <div className="col-4 col-md-3 mt-3 second-content">
                        <img
                          className="card-image"
                          src={`/assets/images/products/bul-coz-kazan.png`}
                          alt=""
                        />
                        <p className="card-text">Bul Çöz Kazan</p>
                      </div>

                      <div className="col-4 col-md-3 mt-3 second-content">
                        <img
                          className="card-image wide"
                          src={`/assets/images/products/alt-ust.png`}
                          alt=""
                        />
                        <p className="card-text">Alt Üst</p>
                      </div>
                      <div className="col-4 col-md-3 mt-3 second-content">
                        <img
                          className="card-image wide"
                          src={`/assets/images/products/tarzim-cepte.png`}
                          alt=""
                        />
                        <p className="card-text">Tarzım Cepte</p>
                      </div>
                      <div className="col-4 col-md-3 mt-3 second-content">
                        <img
                          className="card-image"
                          src={`/assets/images/products/namaz-vakitleri.png`}
                          alt=""
                        />
                        <p className="card-text">Namaz Vakitleri</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )} />
        </div>
        <Media query="(max-width: 480px)" render={() => (
          <div>
            <div className="products-footer mt-3">
              <div className=" m-0 pl-4 align-items-center products-footer-row">
                <div className=" p-0 products-footer-title">
                  <b>Business <br /> Partners</b>
                </div>
              </div>
              <Carousel
                ref={this.refFooterCarousel}
                dots={false}
                className="footer-carousel pl-4"
              >
                <div className="first d-flex">
                  <div className="col-8 col-lg-9 d-flex pl-lg-5 p-0 align-items-center">
                    <div className="footer-image-content row">
                      <div className="col-12 d-flex p-0">
                        <img
                          className="footer-image"
                          src={`/assets/images/products/bip.png`}
                          alt=""
                        />
                        <img
                          className="footer-image"
                          src={`/assets/images/products/kittani.png`}
                          alt=""
                        />
                        <img
                          className="footer-image"
                          src={`/assets/images/products/iab.png`}
                          alt=""
                        />
                        <img
                          className="footer-image"
                          src={`/assets/images/products/solid.png`}
                          alt=""
                        />
                        <img
                          className="footer-image"
                          src={`/assets/images/products/turkcell.png`}
                          alt=""
                        />
                      </div>
                      <div className="col-12 d-flex p-0">
                        <img
                          className="footer-image"
                          src={`/assets/images/products/yaani.png`}
                          alt=""
                        />
                        <img
                          className="footer-image"
                          src={`/assets/images/products/paycell.png`}
                          alt=""
                        />
                        <img
                          className="footer-image"
                          src={`/assets/images/products/mma.png`}
                          alt=""
                        />
                        <img
                          className="footer-image"
                          src={`/assets/images/products/gnc.png`}
                          alt=""
                        />
                        <img
                          className="footer-image-fenerbahce"
                          src={`/assets/images/products/fenerbahce.png`}
                          alt=""
                        />
                        <img
                          className="footer-image"
                          src={`/assets/images/products/lacivert.png`}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <img
                    src="/assets/images/about-us/right-arrow.svg"
                    onClick={() => this.handleArrowClickFooterMobile("second")}
                    className="right-arrow"
                    alt="prev"
                  />
                </div>
                <div className="second d-flex mt-1">
                  <img
                    src="/assets/images/about-us/left-arrow.svg"
                    onClick={() => this.handleArrowClickFooterMobile("first")}
                    className="left-arrow ml-3"
                    alt="next"
                  />
                  <div className="p-0 col-8 col-lg-9 d-flex pl-lg-5 align-items-center">
                    <div className="footer-image-content d-flex align-items-center">
                      <img
                        className="footer-image-turk-telekom"
                        src={`/assets/images/products/turk_telekom.png`}
                        alt=""
                      />
                      <img
                        className="footer-image-vodafone"
                        src={`/assets/images/products/vodafone.png`}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        )} />
        <Media query="(min-width: 481px)" render={() => (
          <>
            <div className="products-footer mt-3">
              <div className="row m-0 p-0 align-items-center products-footer-row">
                <div className="col-4 col-lg-2 p-3 products-footer-title">
                  <b>Business Partners</b>
                </div>
                <div className="col-8 col-lg-10 d-flex pl-lg-5 align-items-center pr-lg-5">
                  <div className={`arrows`}>
                    <img
                      src="/assets/images/about-us/left-arrow.svg"
                      onClick={() => this.handleArrowClickFooter("first")}
                      className="left-arrow"
                      alt=""
                      style={{ display: this.state.visibleArrowFooter, cursor: "pointer" }}
                    />
                  </div>
                  <div className="footer-image-content mr-5">
                    {this.state.chosenPartFooter === "first" && (
                      <>
                        <div style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                          <img
                            className="footer-image"
                            src={`/assets/images/products/bip.png`}
                            alt=""
                          />
                          <img
                            className="footer-image"
                            src={`/assets/images/products/kittani.png`}
                            style={{ height: "42px" }}
                            alt=""
                          />
                          <img
                            className="footer-image-fenerbahce"
                            src={`/assets/images/products/fenerbahce.png`}
                            style={{ height: "55px" }}
                            alt=""
                          />
                          <img
                            className="footer-image mb-0"
                            src={`/assets/images/products/gnc.png`}
                            style={{ height: "40px" }}
                            alt=""
                          />
                          <img
                            className="footer-image"
                            src={`/assets/images/products/iab.png`}
                            alt=""
                          />
                          <img
                            className="footer-image"
                            src={`/assets/images/products/lacivert.png`}
                            alt=""
                          />
                        </div>
                        <div>
                          <img
                            className="footer-image"
                            src={`/assets/images/products/mma.png`}
                            alt=""
                          />
                          <img
                            className="footer-image"
                            src={`/assets/images/products/paycell.png`}
                            alt=""
                          />
                          <img
                            className="footer-image"
                            src={`/assets/images/products/solid.png`}
                            alt=""
                          />
                          <img
                            className="footer-image mr-0"
                            src={`/assets/images/products/turkcell.png`}
                            alt=""
                          />
                          <img
                            className="footer-image-turk-telekom"
                            src={`/assets/images/products/turk_telekom.png`}
                            alt=""
                          />
                        </div>
                      </>
                    )}
                    {this.state.chosenPartFooter === "second" && (
                      <div>
                        <img
                          className="footer-image-vodafone ml-3"
                          src={`/assets/images/products/vodafone.png`}
                          alt=""
                        />
                        <img
                          className="footer-image"
                          src={`/assets/images/products/yaani.png`}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                  <div className={`arrows`}>
                    <img
                      src="/assets/images/about-us/right-arrow.svg"
                      onClick={() => this.handleArrowClickFooter("second")}
                      className="right-arrow"
                      alt=""
                      style={{ display: this.state.visibleArrowFooter === "none" ? "inline" : "none", cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )} />

        {/* <div className="products-footer mt-3">
            <div className="row m-0 p-0 align-items-center products-footer-row">
              <div className="col-4 col-lg-3 p-3 products-footer-title">
                <b>Business Partners</b>
              </div>
              <div className="col-8 col-lg-9 d-flex pl-lg-5 align-items-center">
                <div className="footer-image-content">
                  <div>
                    <img
                      className="footer-image"
                      src={`/assets/images/products/bip.png`}
                      alt=""
                    />
                    <img
                      className="footer-image"
                      src={`/assets/images/products/kittani.png`}
                      alt=""
                    />
                    <img
                      className="footer-image"
                      src={`/assets/images/products/iab.png`}
                      alt=""
                    />
                    <img
                      className="footer-image"
                      src={`/assets/images/products/solid.png`}
                      alt=""
                    />
                    <img
                      className="footer-image"
                      src={`/assets/images/products/turkcell.png`}
                      alt=""
                    />
                    <img
                      className="footer-image"
                      src={`/assets/images/products/yaani.png`}
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      className="footer-image"
                      src={`/assets/images/products/paycell.png`}
                      alt=""
                    />
                    <img
                      className="footer-image"
                      src={`/assets/images/products/mma.png`}
                      alt=""
                    />
                    <img
                      className="footer-image"
                      src={`/assets/images/products/gnc.png`}
                      alt=""
                    />
                    <img
                      className="footer-image-fenerbahce"
                      src={`/assets/images/products/fenerbahce.png`}
                      alt=""
                    />
                    <img
                      className="footer-image"
                      src={`/assets/images/products/lacivert.png`}
                      alt=""
                    />
                    <img
                      className="footer-image-turk-telekom"
                      src={`/assets/images/products/turk_telekom.png`}
                      alt=""
                    />
                    <img
                      className="footer-image-vodafone"
                      src={`/assets/images/products/vodafone.png`}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
      </div>
    );
  }
}
