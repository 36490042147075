import React from "react";
import { Link } from "react-router-dom";
import AppStore from "../../Store";
import "./header.scss";

export default class Header extends React.Component<{
  appStore: AppStore;
  history: any;
}> {
  render() {
    return (
      <div id="header">
        <div className="my-header">
          <div className="logo">
            <Link to="/">
              <img
                src="/assets/images/logo-mobile.gif"
                className="logo-image-mobile"
                alt=""
              />
              <img
                src="/assets/images/logo-desktop.gif"
                className="logo-image-desktop"
                alt=""
              />
            </Link>
          </div>
          <div className="links">
            <ul>
              <li>
                <Link to="/about" className="pages-buttons">about us</Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="/products" className="pages-buttons">products</Link>{" "}
              </li>
              <li>
                <Link to="/people" className="pages-buttons">
                  our people
                </Link>{" "}
              </li>
              <li>
                <Link to="/us" className="pages-buttons">
                  us
                </Link>{" "}
              </li>
              <li>
                <Link to="/contact" className="pages-buttons">
                  contact us
                </Link>{" "}
              </li>
              {/* <li>
                <a href="/4blog" className="pages-buttons">
                  4blog
                </a>{" "}
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
