import {Modal} from "antd";
import parse from "html-react-parser";
import {inject, observer} from "mobx-react";
import React from "react";
import closeIcon from "../../../assets/images/close-icon.png";
import FireRemoteService from "../../../core/services/fire-remote";
import {UI} from "../../../core/utilities/ui";
import AppStore from "../../../Store";
import "./home-modal.scss";

@inject("appStore")
@observer
export default class HomeModal extends React.Component<{ appStore: AppStore }> {

  private handleModalClose(): void {
    this.props.appStore.setModalHomeVisibility(false);
  }

  render() {
    return (
      <Modal
        className="home-modal"
        maskStyle={UI.modalMaskStyle()}
        visible={this.props.appStore.isModalHomeAvailable && this.props.appStore.isModalHomeVisible}
        closeIcon={<img src={closeIcon} alt="close"/>}
        onCancel={() => this.handleModalClose()}
        footer={false}
        closable
        centered
        destroyOnClose
      >
        <div className="body">
          <img
            className="background"
            src={FireRemoteService.getStringValue(FireRemoteService.home_popup_bg)}
            alt="background"
          />
          <div className="content">
            <span className="title">
              {parse(FireRemoteService.getStringValue(FireRemoteService.home_popup_title))}
            </span>
            <span className="desc-1">
              {parse(FireRemoteService.getStringValue(FireRemoteService.home_popup_desc1))}
            </span>
            <span className="desc-2">
              {parse(FireRemoteService.getStringValue(FireRemoteService.home_popup_desc2))}
            </span>
          </div>
          <img
            className="icon"
            src={FireRemoteService.getStringValue(FireRemoteService.home_popup_icon)}
            alt="icon"
          />
        </div>
      </Modal>
    );
  }
}
