import firebase from 'firebase/app';
import 'firebase/remote-config';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDrWVKiCfDgLMduKaPws2ePnUvLj3CKSt0",
  authDomain: "play-website-8c8fa.firebaseapp.com",
  projectId: "play-website-8c8fa",
  storageBucket: "play-website-8c8fa.appspot.com",
  messagingSenderId: "570115197092",
  appId: "1:570115197092:web:4a8a10a76f42e11215be31",
  measurementId: "G-5M9LWFMFE4",
};

const fire = firebase.initializeApp(firebaseConfig);



fire.remoteConfig().settings.minimumFetchIntervalMillis = 3600000;

export default fire;
