import { inject, observer } from "mobx-react";
import React from "react";
import "./about-us.scss";
import AppStore from "../../Store";
import Header from "../../components/header/header";
import { AboutUsState } from "../../core/interfaces/states/about-us.state";

@inject("appStore")
@observer
export default class AboutUs extends React.Component<{
  appStore: AppStore;
  history: any;
}> {
  state: AboutUsState = {
    height: window.innerHeight,
    chosenPart: "mobile",
    backgroundColorController: 0,
  };

  private listenWindowSize = () => {
    this.setState({
      height: window.innerHeight,
    });
  };

  async componentDidMount() {
    window.addEventListener("resize", this.listenWindowSize);
  }

  private handleArrowClick = async (way: string) => {
    switch (way) {
      case "left":
        await this.setState(() => {
          switch (this.state.chosenPart) {
            case "mobile":
              return { chosenPart: "people" };
            case "people":
              return { chosenPart: "creative" };
            case "creative":
              return { chosenPart: "mobile" };
          }
        });
        break;

      case "right":
        await this.setState(() => {
          switch (this.state.chosenPart) {
            case "mobile":
              return { chosenPart: "creative" };
            case "creative":
              return { chosenPart: "people" };
            case "people":
              return { chosenPart: "mobile" };
          }
        });
        break;
    }
  };

  render() {
    return (
      <div id="about-us" style={{ height: this.state.height }}>
        <Header appStore={this.props.appStore} history={this.props.history} />

        <div className="content">
          <div className={`triangle-big-box`}>
            <img
              src="/assets/images/about-us/left-arrow.svg"
              onClick={() => this.handleArrowClick("left")}
              className="left-arrow"
              alt=""
            />
            <img
              src="/assets/images/about-us/right-arrow.svg"
              onClick={() => this.handleArrowClick("right")}
              className="right-arrow"
              alt=""
            />
            <img
              src={`/assets/images/about-us/part-${this.state.chosenPart}.svg`}
              className={`triangle-content triangle-${this.state.chosenPart}`}
              alt=""
            />
          </div>

          {this.state.chosenPart === "mobile" && (
            <div className="mobile">
              <img
                className="mobile-items top-left"
                src="/assets/images/about-us/prize-mobile.png"
                alt=""
              />

              <img
                className="mobile-items top-right"
                src="/assets/images/about-us/about_us_mobile_desktop.png"
                // src="/assets/images/about-us/price-mobile-blue-top.png"
                alt=""
              />

              <img
                className="mobile-items bottom-left"
                // src="/assets/images/about-us/price-mobile-blue.png"
                src="/assets/images/about-us/about_us_mobile_desktop_1.png"
                alt=""
              />

              <img
                className="mobile-items bottom-right"
                src="/assets/images/about-us/prize-mobile-2.png"
                alt=""
              />
            </div>
          )}
          {this.state.chosenPart === "creative" && (
            <div className="creative">
              {/* {() => {
                for (let i = 1; i < 7; i++) {
                  return (
                    <img
                      className={`creative-items creative-${i}`}
                      src={`/assets/images/about-us/creative-${i}.png`}
                      alt=""
                    />
                  );
                }
              }} */}

              <img
                className="creative-items creative-1"
                src="/assets/images/about-us/creative-1.png"
                alt=""
              />
              <img
                className="creative-items creative-2"
                src="/assets/images/about-us/creative-2.png"
                alt=""
              />
              <img
                className="creative-items creative-3"
                src="/assets/images/about-us/creative-3.png"
                alt=""
              />
              <img
                className="creative-items creative-4"
                src="/assets/images/about-us/creative-4.png"
                alt=""
              />
              <img
                className="creative-items creative-5"
                src="/assets/images/about-us/creative-5.png"
                alt=""
              />
              <img
                className="creative-items creative-6"
                src="/assets/images/about-us/creative-6.png"
                alt=""
              />
            </div>
          )}
          {this.state.chosenPart === "people" && (
          <div className="people">
             <img
                className="people-items people-1"
                src="/assets/images/about-us/people-1.png"
                alt=""
              />
                <img
                className="people-items people-2"
                src="/assets/images/about-us/people-2.png"
                alt=""
              />
                <img
                className="people-items people-3"
                src="/assets/images/about-us/people-3.png"
                alt=""
              />
                <img
                className="people-items people-4"
                src="/assets/images/about-us/people-4.png"
                alt=""
              />
            
          </div>
          )}
        </div>
      </div>
    );
  }
}
