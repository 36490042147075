export class Constants {

    public static readonly firstRatioImage = [1, 7, 10, 16, 18, 23, 24, 38, 44, 46, 47, 53, 56];
    public static readonly secondRatioImage = [2, 3, 6, 8, 11, 15, 17, 19, 23, 26, 31, 33, 36, 41, 43, 45, 48, 49, 52, 54, 57];
    public static readonly thirdRatioImage = [4, 11, 13, 21, 27, 39, 50];
    public static readonly fourthRatioImage = [5, 9, 12, 14, 20, 22, 25, 28, 30, 32, 34, 35, 37, 40, 42, 51, 55];
    


   public static readonly  allImages = [
        {
            id: 1,
            src: "/assets/images/us/row1-01@4x.png"
        },
        {
            id: 2,
            src: "/assets/images/us/row1-02@4x.png"
        },
        {
            id: 3,
            src: "/assets/images/us/row1-03@4x.png"
        },
        {
            id: 4,
            src: "/assets/images/us/row1-04@4x.png"
        },
        {
            id: 5,
            src: "/assets/images/us/row1-05@4x.png"
        },
        {
            id: 6,
            src: "/assets/images/us/row1-06@4x.png"
        },
        {
            id: 7,
            src: "/assets/images/us/row1-07@4x.png"
        },
        {
            id: 8,
            src: "/assets/images/us/row1-08@4x.png"
        },
        {
            id: 9,
            src: "/assets/images/us/row1-09@4x.png"
        },
        {
            id: 10,
            src: "/assets/images/us/row1-10@4x.png"
        },
        {
            id: 11,
            src: "/assets/images/us/row1-11@4x.png"
        },
        {
            id: 12,
            src: "/assets/images/us/row2-01@4x.png"
        },
        {
            id: 13,
            src: "/assets/images/us/row2-02@4x.png"
        },
        {
            id: 14,
            src: "/assets/images/us/row2-03@4x.png"
        },
        {
            id: 15,
            src: "/assets/images/us/row2-04@4x.png"
        },
        {
            id: 16,
            src: "/assets/images/us/row2-05@4x.png"
        },
        {
            id: 17,
            src: "/assets/images/us/row2-06@4x.png"
        },
        {
            id: 18,
            src: "/assets/images/us/row2-07@4x.png"
        },
        {
            id: 19,
            src: "/assets/images/us/row2-08@4x.png"
        },
        {
            id: 20,
            src: "/assets/images/us/row2-09@4x.png"
        },
        {
            id: 21,
            src: "/assets/images/us/row2-10@4x.png"
        },
        {
            id: 22,
            src: "/assets/images/us/row2-11@4x.png"
        },
        {
            id: 23,
            src: "/assets/images/us/row2-12@4x.png"
        },
        {
            id: 24,
            src: "/assets/images/us/row3Left-01@4x.png"
        },
        {
            id: 25,
            src: "/assets/images/us/row3Left-02@4x.png"
        },
        {
            id: 26,
            src: "/assets/images/us/row3Left-03@4x.png"
        },
        {
            id: 27,
            src: "/assets/images/us/row3Left-04@4x.png"
        },
        {
            id: 28,
            src: "/assets/images/us/row3Left-05@4x.png"
        },
        {
            id: 29,
            src: "/assets/images/us/logo.png"
        },
        {
            id: 30,
            src: "/assets/images/us/row3Right-01@4x.png"
        },
        {
            id: 31,
            src: "/assets/images/us/row3Right-02@4x.png"
        },
        {
            id: 32,
            src: "/assets/images/us/row3Right-03@4x.png"
        },
        {
            id: 33,
            src: "/assets/images/us/row3Right-04@4x.png"
        },
        {
            id: 34,
            src: "/assets/images/us/row3Right-05@4x.png"
        },
        {
            id: 35,
            src: "/assets/images/us/row3Right-06@4x.png"
        },
        {
            id: 36,
            src: "/assets/images/us/row4-01@4x.png"
        },
        {
            id: 37,
            src: "/assets/images/us/row4-02@4x.png"
        },
        {
            id: 38,
            src: "/assets/images/us/row4-03@4x.png"
        },
        {
            id: 39,
            src: "/assets/images/us/row4-04@4x.png"
        },
        {
            id: 40,
            src: "/assets/images/us/row4-05@4x.png"
        },
        {
            id: 41,
            src: "/assets/images/us/row4-06@4x.png"
        },
        {
            id: 42,
            src: "/assets/images/us/row4-07@4x.png"
        },
        {
            id: 43,
            src: "/assets/images/us/row4-08@4x.png"
        },
        {
            id: 44,
            src: "/assets/images/us/row4-09@4x.png"
        },
        {
            id: 45,
            src: "/assets/images/us/row4-10@4x.png"
        },
        {
            id: 46,
            src: "/assets/images/us/row4-11@4x.png"
        },
        {
            id: 47,
            src: "/assets/images/us/row5-01@4x.png"
        },
        {
            id: 48,
            src: "/assets/images/us/row5-02@4x.png"
        },
        {
            id: 49,
            src: "/assets/images/us/row5-03@4x.png"
        },
        {
            id: 50,
            src: "/assets/images/us/row5-04@4x.png"
        },
        {
            id: 51,
            src: "/assets/images/us/row5-05@4x.png"
        },
        {
            id: 52,
            src: "/assets/images/us/row5-06@4x.png"
        },
        {
            id: 53,
            src: "/assets/images/us/row5-07@4x.png"
        },
        {
            id: 54,
            src: "/assets/images/us/row5-08@4x.png"
        },
        {
            id: 55,
            src: "/assets/images/us/row5-09@4x.png"
        },
        {
            id: 56,
            src: "/assets/images/us/row5-10@4x.png"
        },
        {
            id: 57,
            src: "/assets/images/us/row5-11@4x.png"
        }
    ]
}
