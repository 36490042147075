import { Modal } from "antd";
import { inject, observer } from "mobx-react";
import React, { ReactNode } from 'react';
import Header from "../../components/header/header";
import { Constants } from "../../core/helpers/constants";
import AppStore from "../../Store";
import "./us.scss";
@inject("appStore")
@observer
export default class Us extends React.Component<{
    appStore: AppStore;
    history: any;
}> {
    state = {
        currentImageId: 0
    };

    handleImageModalVisible = (currentImageId: number) => {
        this.setState({
            currentImageId,
        });
    }

    getCurrentSelectedImage = (): ReactNode => {
        const filteredImages = Constants.allImages.filter(img => img.id === this.state.currentImageId);
        if (filteredImages.length > 0) {
            return <img className="filtered-image" alt="us" src={filteredImages[0].src} />
        } else {
            return <React.Fragment />
        }
    }

    render() {
        return (
            <div id="us">
                <Header appStore={this.props.appStore} history={this.props.history} />
                <div className="content">
                    {Constants.allImages.map(img => {
                        return <img alt="us" onClick={() => this.handleImageModalVisible(img.id)}
                            key={img.id} src={img.src} className={`
                            ${Constants.firstRatioImage.includes(img.id) ? "first-ratio-image" : ""} 
                            ${Constants.secondRatioImage.includes(img.id) ? "second-ratio-image" : ""}
                            ${Constants.thirdRatioImage.includes(img.id) ? "third-ratio-image" : ""}
                            ${Constants.fourthRatioImage.includes(img.id) ? "fourth-ratio-image" : ""}
                            ${img.src.substring(18, 22)}`} />
                    })}

                    <Modal
                        className="us-page-modal-img"
                        visible={this.state.currentImageId > 0}
                        onCancel={() => this.handleImageModalVisible(0)}
                        width={450}
                        closable={false}
                        centered
                        footer={null}
                        getContainer={document.getElementById("us")}
                        destroyOnClose={true}>
                        <div className="us-page-modal-img-wrapper">
                            {this.getCurrentSelectedImage()}
                        </div>
                    </Modal>
                </div>

            </div>
        );
    }
}
