import { inject, observer } from "mobx-react";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.scss";
import HomeModal from "./components/modals/home-modal/home-modal";
import AboutUs from "./pages/about-us/about-us";
import Contact from "./pages/contact/contact";
import HomePage from "./pages/home/home";
import OurPeople from "./pages/our-people/our-people";
import Products from "./pages/products/products";
import Us from "./pages/us/us";
import AppStore from "./Store";

@inject("appStore")
@observer
export default class App extends React.Component<{
  appStore: AppStore;
}> {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/people" component={OurPeople} />
          <Route exact path="/about" component={AboutUs} />
          <Route exact path="/products" component={Products} />
          <Route exact path="/us" component={Us} />
          <Route component={() => <Redirect to="/" />} />
        </Switch>
        <HomeModal appStore={this.props.appStore}/>
      </BrowserRouter>
    );
  }
}
